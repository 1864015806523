import { PageController as SharedPageController } from 'Shared/Page/Page';

const NAME = 'page';

const MESSAGE_HANDLERS = [
    'autocompleteItemSelected.masthead',
    'autocompleteItemSelected.odp',
];

class PageController extends SharedPageController {
    onAutocompleteItemSelected (evt) {
        const location = evt.data.data.location;

        if ('city' in location) {
            this.redirectToSerp(location);
        }
    }

    async redirectToSerp (location) {
        const body = new FormData();
        body.append('filters[city]', location.city);

        if ('district' in location) {
            body.append('filters[district]', location.district);
        }

        if ('neighbourhood' in location) {
            body.append('filters[neighbourhood]', location.neighbourhood);
        }

        const response = await fetch(Platform.uris.get_search_urls, {
            'headers': {
                'X-Requested-With': 'XMLHttpRequest',
            },
            'body': body,
            'method': 'POST',
        })
            .then(response => response.json())
            .catch(() => null);

        if (!response) {
            return null;
        }

        window.location.href = response.urls.list.nl;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS.concat(super.messageHandlers);
    }

    get componentName () {
        return NAME;
    }
}

// export for extending
export { PageController, SharedPageController };

// export for direct use
export default {
    'name': NAME,
    'controller': PageController,
};
